import { AppType } from '../types/app';
import { SUBDOMAIN_PREFIXES } from '@/shared/constants/subdomains';
const APP_TYPE_MAPPERS = [
    {
        regExp: new RegExp(`^${SUBDOMAIN_PREFIXES.ja}`),
        type: AppType.JA,
    },
];
export const appType = APP_TYPE_MAPPERS.find(({ regExp }) => window.location.host.match(regExp))?.type ??
    AppType.RL;
