import { jsx as _jsx } from "react/jsx-runtime";
import { ListItem } from '@mui/material';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { ItemButton } from '../styled';
import { roomStore } from '@/shared/model/room';
import DebugService from '@/shared/api/services/DebugService';
export const ReportBugOption = observer(({ onClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { room } = roomStore;
    const handleReportBugClick = useCallback(() => {
        onClose();
        if (!room.id) {
            enqueueSnackbar("You're not in the room!", {
                autoHideDuration: 3000,
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom',
                },
            });
            return;
        }
        enqueueSnackbar('Report sent!', {
            autoHideDuration: 3000,
            variant: 'info',
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
            },
        });
        DebugService.saveReportLog();
    }, [enqueueSnackbar, onClose, room.id]);
    return (_jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleReportBugClick, style: { color: 'red' }, children: "Report bug" }) }));
});
