import styled, { css } from 'styled-components';
import THEME_IMAGES from '@/shared/assets/images/theme';
import { ROOM_THEMES } from '@/shared/constants/room-themes';
import theme from '@/shared/lib/theme';
export const BackgroundTheme = styled.div(({ themeName, isSpeedDating, isMatched }) => {
    const getBackgroundTheme = () => {
        if (isSpeedDating) {
            if (isMatched) {
                return css `
            background-image: url(${THEME_IMAGES[ROOM_THEMES.speedDatingMatch].main});

            transition: background-image 0.5s ease-in-out;
          `;
            }
            return css `
          background-image: url(${THEME_IMAGES[ROOM_THEMES.speedDating].main});

          transition: background-image 0.5s ease-in-out;
        `;
        }
        if (themeName === 'default') {
            return css `
          background: rgba(0, 0, 0, 0.2);
        `;
        }
        return css `
        background-image: url(${THEME_IMAGES[themeName].main});
      `;
    };
    return css `
      ${getBackgroundTheme()}

      left: 0;
      background-attachment: fixed;
      background-size: cover;
      min-height: 100%;
      min-width: 100vw;
      position: absolute;
      overflow: hidden;
      z-index: -2;
    `;
});
export const Logo = styled.img `
  position: fixed;
  pointer-events: none;
  z-index: 2;
  ${({ bottom, left, width }) => css `
    left: ${left}px;
    bottom: ${bottom}px;
    width: ${width}px;
  `}
  height: auto;
  user-select: none;

  ${theme.breakpoints.down('md')} {
    z-index: -1;
  }
`;
export const RightSideStuff = styled.img `
  position: fixed;
  pointer-events: none;
  ${({ top, right, height, width }) => css `
    top: ${top}px;
    right: ${right}px;
    height: ${height}px;
    width: ${width}px;
  `}
  z-index: -1;
  user-select: none;
`;
export const Stuff = styled.img `
  position: fixed;
  pointer-events: none;
  z-index: -1;
  ${({ bottom, right, left, top, width }) => css `
    ${top &&
    css `
      top: ${top}%;
    `};
    ${bottom &&
    css `
      bottom: ${bottom}%;
    `};
    ${right &&
    css `
      right: ${right}px;
    `};
    ${left &&
    css `
      left: ${left}px;
    `};
    width: ${width}px;
    transform: rotate(${width}deg);
  `}
  height: auto;
  user-select: none;
`;
export const LeftSideStuff = styled.img `
  position: fixed;
  pointer-events: none;
  ${({ top, left, height, width }) => css `
    top: ${top}px;
    left: ${left}px;
    height: ${height}px;
    width: ${width}px;
  `}

  z-index: 100;
  user-select: none;

  ${theme.breakpoints.down('md')} {
    z-index: -1;
  }
`;
