import { makeAutoObservable } from 'mobx';
class RoomNotificationsStore {
    _interactions = [];
    _notes = [];
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get interactions() {
        return this._interactions;
    }
    addInteraction = (roomNotification) => this._interactions.push(roomNotification);
    addNote = (roomNotification) => this._notes.push(roomNotification);
    clear = () => {
        this._interactions.length = 0;
        this._notes.length = 0;
    };
}
export const roomNotificationsStore = new RoomNotificationsStore();
