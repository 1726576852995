import axios from 'axios';
import { API } from '../constants/api';
import { appType } from '../config';
const getBaseUrl = () => process.env.NODE_ENV === 'production'
    ? 'https://api.realush.com'
    : // 'http://localhost:63011';
        'https://api.realush.com'; // 'https://stage-api.realush.com'
const getImagesUrl = () => process.env.NODE_ENV === 'production'
    ? 'https://backoffice.realush.com/uploads/images'
    : 'https://backoffice.realush.com/uploads/images';
const axiosInstance = axios.create({
    baseURL: API.base,
    timeout: 15000,
    withCredentials: true,
    headers: {
        App: appType,
    },
});
axiosInstance.interceptors.request.use(async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.App = appType;
    return config;
}, async (error) => Promise.reject(error));
axiosInstance.interceptors.response.use(async (response) => response, async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && config && !config.sent) {
        config.sent = true;
        const link = getBaseUrl();
        try {
            await axios.post(`${link}${API.refreshTokenPair}`, {}, { withCredentials: true });
            return await axiosInstance.request(config);
        }
        catch {
            return await Promise.reject(error);
        }
    }
    return Promise.reject(error);
});
export const http = {
    getBaseUrl,
    getImagesUrl,
};
export default axiosInstance;
