import { jsx as _jsx } from "react/jsx-runtime";
import { ListItem } from '@mui/material';
import { memo } from 'react';
import { ItemButton } from '../styled';
import { useSignOut } from '@/shared/lib/hooks';
const InnerSignOutOption = () => {
    const { signOut } = useSignOut();
    const handleSignOut = () => {
        signOut();
    };
    return (_jsx(ListItem, { disableGutters: true, onClick: handleSignOut, children: _jsx(ItemButton, { children: "Sign Out" }) }));
};
export const SignOutOption = memo(InnerSignOutOption);
