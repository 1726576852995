import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useId } from 'react';
import { useLocation } from 'react-router-dom';
import { UnreadMessages } from './styled';
import { MessageFilledIcon, MessageIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { headerStore } from '@/shared/model/header';
import CustomTooltip from '@/shared/ui/CustomTooltip';
export const ChatButton = observer(() => {
    const { chatListOpened, friendChatOpened, chatMessageReceived, unreadMessagesCount } = headerStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const handleClick = useCallback(() => {
        if (chatListOpened || friendChatOpened) {
            headerStore.chatListOpened = false;
            headerStore.friendChatOpened = false;
            headerStore.closeAllChats();
            return;
        }
        headerStore.friendsListOpened = false;
        headerStore.chatListOpened = true;
    }, [chatListOpened, friendChatOpened]);
    const unreadMessagesId = useId();
    const amount = unreadMessagesCount.tableChat +
        unreadMessagesCount.friendsChats.reduce((sum, chat) => sum + chat.count, 0);
    const max = 100;
    const counter = amount >= max ? '99+' : amount;
    const color = isLobby ? 'black' : 'white';
    const open = chatListOpened || friendChatOpened;
    return (_jsx(CustomTooltip, { placement: 'bottom', title: open ? 'Close chat' : 'Open chat', children: _jsxs(IconButton, { id: 'button2', color: open ? 'primary' : color, size: 'small', onClick: handleClick, "aria-describedby": chatMessageReceived ? unreadMessagesId : undefined, children: [open ? _jsx(MessageFilledIcon, {}) : _jsx(MessageIcon, {}), amount > 0 && !open ? (_jsx(UnreadMessages, { id: unreadMessagesId, isMax: amount >= max, "aria-label": 'You have unread messages', children: counter })) : null] }) }));
});
