import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotificationHubReceive, useRoomDisconnect } from '@/shared/lib/hooks';
import { HUB_METHODS } from '@/shared/constants/hub';
import { modalStore } from '@/shared/model/modal';
import SpeedDatingStartedNotification from '@/entities/notifications/SpeedDatingStartedNotification';
import { roomStore } from '@/shared/model/room';
import { ROUTES } from '@/shared/constants/routes';
import SpeedDatingEndedNotification from '@/entities/notifications/SpeedDatingEndedNotification';
import { speedDatingStore } from '@/shared/model/speed-dating';
import RoomService from '@/shared/api/services/RoomService';
import { isAxiosError } from 'axios';
import { appType } from '@/shared/config';
const useSpeedDatingStartedHandler = (userRole) => {
    const { room: { id }, } = roomStore;
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    useNotificationHubReceive(HUB_METHODS.notification.receive.speedDatingEventStarted, async (message) => {
        if (userRole === 'performer') {
            return;
        }
        if (id === message.roomID) {
            return;
        }
        if (!isLobby && !id) {
            return;
        }
        speedDatingStore.isClosed = false;
        const response = await RoomService.getRoomDetails(message.roomID);
        if (!response || isAxiosError(response)) {
            return;
        }
        const { data } = response;
        if (data.appType.toLowerCase() === appType) {
            modalStore.open({
                content: _jsx(SpeedDatingStartedNotification, { roomId: message.roomID }),
            });
        }
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.speedDatingEventEnded, async (message) => {
        if (userRole === 'performer') {
            return;
        }
        speedDatingStore.isClosed = true;
        if (id === message.roomID) {
            await disconnect();
            navigate(ROUTES.rooms.path);
            modalStore.open({
                content: _jsx(SpeedDatingEndedNotification, {}),
            });
        }
    });
};
export default useSpeedDatingStartedHandler;
