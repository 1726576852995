import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTES } from '@/shared/constants/routes';
import MainHeader from '@/widgets/MainHeader';
import { useNotificationHubReceive, useSignOut } from '@/shared/lib/hooks';
import { HUB_METHODS } from '@/shared/constants/hub';
import { LobbyHeader } from '@/widgets/lobby-header';
export const DynamicHeader = observer(() => {
    const { pathname } = useLocation();
    const { signOut } = useSignOut();
    useNotificationHubReceive(HUB_METHODS.notification.receive.logout, async (_) => {
        await signOut();
    });
    const hideHeader = pathname.match(new RegExp(`${ROUTES.rooms.path}/\\d+`));
    if (hideHeader) {
        return _jsx(_Fragment, {});
    }
    const notDefault = pathname.startsWith(ROUTES.rooms.path) || pathname.startsWith(ROUTES.speakerDashboard.path);
    return notDefault ? _jsx(LobbyHeader, {}) : _jsx(MainHeader, {});
});
