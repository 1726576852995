import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useState, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChatInfo, FriendsChatList, MessageCounter, ProfilePicture, SubTitle, TableChatContainer, TextWrapper, UserMessage, UserName, NoResultWrapper, EmptyListWrapper, EmptyListTitle, EmptyListText, } from './styled';
import MessagesSettings from './model/MessagesSettings';
import useTableChatConnection from './lib/hooks/useTableChatConnection';
import FriendChat from './model/FriendChat';
import useSearchFriends from './lib/hooks/useSearchFriends';
import FriendsChat from '@/features/FriendsChat';
import TableChat from '@/features/TableChat';
import AsideLayout, { AsideSection, HeadingContainer, Title, } from '@/entities/containers/AsideLayout';
import NotificationHub from '@/shared/api/hubs/NotificationHub';
import { SearchStatusIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { friendStore } from '@/shared/model/friends';
import { headerStore } from '@/shared/model/header';
import { userStore } from '@/shared/model/user';
import CustomTooltip from '@/shared/ui/CustomTooltip';
import SearchBar from '@/shared/ui/SearchBar';
import TextElement from '@/shared/ui/TextElement';
import { HUB_METHODS } from '@/shared/constants/hub';
import { tablePlaceholder } from '@/shared/assets/images';
import AsideModal from '@/entities/containers/AsideModal';
const ChatAside = observer(() => {
    const { chatListOpened } = headerStore;
    const { user, userTableName } = userStore;
    const { friends } = friendStore;
    const [isChatOpened, setChatOpened] = useState(false);
    const [isTableChat, setTableChat] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const handleChange = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);
    const [searchedFriends, sortedSearchedFriends] = useSearchFriends(searchValue);
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    // Close chat component after closing aside
    useEffect(() => {
        if (!chatListOpened) {
            setChatOpened(false);
            setTableChat(false);
        }
    }, [chatListOpened]);
    const { connected, usernameColorScheme, chatHistory, newMessageReceived } = useTableChatConnection(chatListOpened && isTableChat);
    const withTableChat = userTableName !== 'Bar';
    const handleOpenChat = useCallback((receiverId) => async () => {
        friendStore.activeFriendChatId = receiverId;
        const friendEntity = friends.find((friend) => friend.id === receiverId);
        if (friendEntity?.lastChatMessage?.unread) {
            headerStore.cleanUnreadMessageCount(receiverId);
            if (!headerStore.unreadMessagesCount) {
                headerStore.chatMessageReceived = false;
            }
            const clearedFriends = friends.filter((friend) => friend.id !== receiverId);
            friendStore.friends = [
                ...clearedFriends,
                { ...friendEntity, lastChatMessage: { ...friendEntity.lastChatMessage, unread: false } },
            ];
        }
        setChatOpened(true);
        await NotificationHub.sendMessage(HUB_METHODS.notification.send.readFriendChat, {
            id: user.id,
            friendId: receiverId,
        });
    }, [user.id, friends]);
    const handleOpenTableChat = useCallback(() => {
        if (userTableName !== 'Bar') {
            setChatOpened(true);
            setTableChat(true);
        }
    }, [userTableName]);
    const handleCloseChat = useCallback(() => {
        friendStore.activeChatHistory = [];
        friendStore.activeFriendChatId = 0;
        setChatOpened(false);
        setTableChat(false);
    }, []);
    const chat = isTableChat ? (_jsx(TableChat, { connected: connected, chatHistory: chatHistory, usernameColorScheme: usernameColorScheme, onClose: handleCloseChat })) : (_jsx(FriendsChat, { onClose: handleCloseChat }));
    const lastTableChatMessage = chatHistory[chatHistory.length - 1];
    const displayedTableChatMessage = lastTableChatMessage
        ? `${lastTableChatMessage.username === user.userName
            ? 'You: '
            : `${lastTableChatMessage.username}: `}${lastTableChatMessage.message}`
        : 'No messages here yet';
    return (_jsx(AsideModal, { open: chatListOpened, children: _jsx(AsideLayout, { children: isChatOpened ? (chat) : (_jsxs(AsideSection, { gap: '8px', children: [_jsxs(HeadingContainer, { children: [_jsx(Title, { children: "Messages" }), _jsx(MessagesSettings, {})] }), _jsx(SearchBar, { value: searchValue, onChange: handleChange }), withTableChat && !searchValue ? (_jsx(CustomTooltip, { placement: 'bottom', title: 'Open table chat', arrow: false, children: _jsxs(TableChatContainer, { as: 'article', onClick: handleOpenTableChat, children: [_jsx(ProfilePicture, { src: tablePlaceholder, alt: 'your table' }), _jsxs(TextWrapper, { children: [_jsx(UserName, { color: 'darkGray', children: "Table Chat" }), _jsx(UserMessage, { children: displayedTableChatMessage })] }), _jsx(ChatInfo, { children: newMessageReceived ? _jsx(MessageCounter, {}) : null })] }) })) : null, searchValue ? null : _jsx(SubTitle, { children: "Friends messages" }), sortedSearchedFriends.length ? (_jsx(FriendsChatList, { "$isLobby": isLobby, children: sortedSearchedFriends.map(({ id, profilePicture, name, onlineStatus, accepted, lastChatMessage, deleted, }) => {
                            if (!accepted)
                                return null;
                            const isMineLastMessage = id !== lastChatMessage?.senderId;
                            return (_jsx(FriendChat, { id: id, online: onlineStatus, deleted: deleted, profilePicture: profilePicture, name: name, lastMessage: lastChatMessage, isMineLastMessage: isMineLastMessage, onOpen: handleOpenChat(id) }, id));
                        }) })) :
                        !searchValue && _jsxs(EmptyListWrapper, { children: [_jsx(EmptyListTitle, { children: "No messages yet" }), _jsx(EmptyListText, { children: "Select a friend in the Friends tab to chat. If you're at a table, the Table Chat will appear here." })] }), !searchedFriends.length && searchValue ? (_jsxs(NoResultWrapper, { children: [_jsx(SearchStatusIcon, {}), _jsx(TextElement, { fontSize: 16, textAlign: 'center', children: `We couldn't find any matches for “${searchValue}”. Try checking for typos or
                    using complete words.` })] })) : null] })) }) }));
});
export default memo(ChatAside);
