import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Wrapper, UserActionsWrapper } from './styled';
import { ROUTES } from '@/shared/constants/routes';
import { UserProfile } from '@/entities/user-profile';
import { FriendsButton } from '@/features/friends-button';
import { ChatButton } from '@/features/chat-button';
export const UserActions = observer(() => {
    const { pathname } = useLocation();
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    return (_jsx(Wrapper, { children: _jsxs(UserActionsWrapper, { children: [!isDashboard && (_jsxs(_Fragment, { children: [_jsx(ChatButton, {}), _jsx(FriendsButton, {})] })), _jsx(UserProfile, {})] }) }));
});
