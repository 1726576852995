import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Main, Wrapper } from './styled';
import { DynamicHeader } from './components/DynamicHeader';
import RoomTheme from './components/RoomTheme';
import AsideMenu from '@/widgets/AsideMenu';
import Footer from '@/widgets/Footer';
import { Modals } from '@/entities/modals';
import { modalStore } from '@/shared/model/modal';
import { RoomLoader } from '@/entities/room-loader';
const Layout = () => (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(DynamicHeader, {}), _jsxs(Main, { children: [_jsx(Suspense, { fallback: _jsx(RoomLoader, {}), children: _jsx(Outlet, {}) }), _jsx(AsideMenu, {})] }), _jsx(Footer, {}), _jsx(RoomTheme, {})] }), _jsx(Modals, { store: modalStore })] }));
export default memo(Layout);
