import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import pendingNotificationsStore from '../store/pendingNotifications';
import roomPopupsStore from '../store/roomPopups';
import StorageService from '../utils/storage';
import { pendingInteractionsStore } from '@/shared/model/interactions';
import { friendStore } from '@/shared/model/friends';
import { connectionStore } from '@/shared/model/connection';
import { roomStore } from '@/shared/model/room';
import { userStore } from '@/shared/model/user';
import { roomNotificationsStore } from '@/shared/model/room-notifications';
import { popupStore } from '@/shared/model/popup';
import { userMediaStore } from '@/shared/model/user-media';
import { headerStore } from '@/shared/model/header';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { speedDatingStore } from '@/shared/model/speed-dating';
const useRoomDisconnect = () => {
    const { closeSnackbar } = useSnackbar();
    const disconnect = useCallback(async () => {
        closeSnackbar();
        userStore.clear();
        headerStore.clear();
        userMediaStore.clear();
        roomStore.clear();
        roomPopupsStore.clear();
        roomNotificationsStore.clear();
        pendingNotificationsStore.clear();
        pendingInteractionsStore.clear();
        popupStore.clear();
        speedDatingStore.clear();
        friendStore.clearInvites();
        friendStore.removeAllPendingInvite();
        await connectionStore.closeRoomConnections();
        StorageService.remove(STORAGE_KEYS.reloadCount);
    }, [closeSnackbar]);
    useEffect(() => {
        window.onpopstate = () => {
            disconnect();
        };
        return () => {
            window.onpopstate = () => { };
        };
    }, [disconnect]);
    return disconnect;
};
export default useRoomDisconnect;
