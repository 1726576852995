import { makeAutoObservable, runInAction } from 'mobx';
import { isAxiosError } from 'axios';
import { sendInteraction, SendInteractionsMessages } from '../../interactions';
import { userStore } from '../../user/model/store';
import { getVideoScreenshot } from '@/shared/utils/getVideoScreenshot';
import ExplicitContentService from '@/shared/api/services/ExplicitContentService';
const NSFW_CHECK_TIMEOUT_SEC = 30;
class UserMediaStore {
    _userAudioMedia = null;
    _userVideoMedia = null;
    _userAudioTrack = null;
    _userVideoTrack = null;
    _userAudioDeviceId = null;
    _userVideoDeviceId = null;
    _userAudioActive = true;
    _userVideoActive = true;
    _userVideoBackgroundImg = '';
    _nsfwPredictions = null;
    _intervalId = undefined;
    _isNSFWCheckRunning = false;
    _secBeforeNextNSFWCheck = 0;
    _lastNSFWCheckImageBase64 = null;
    constructor() {
        makeAutoObservable(this);
    }
    get userAudioDeviceId() {
        return this._userAudioDeviceId;
    }
    set userAudioDeviceId(userAudioDeviceId) {
        this._userAudioDeviceId = userAudioDeviceId;
    }
    get lastNSFWCheckImageBase64() {
        return this._lastNSFWCheckImageBase64;
    }
    get userVideoDeviceId() {
        return this._userVideoDeviceId;
    }
    set userVideoDeviceId(userVideoDeviceId) {
        this._userVideoDeviceId = userVideoDeviceId;
    }
    get userAudioMedia() {
        return this._userAudioMedia;
    }
    set userAudioMedia(userAudioMedia) {
        this._userAudioMedia = userAudioMedia;
    }
    get userVideoMedia() {
        return this._userVideoMedia;
    }
    set userVideoMedia(userVideoMedia) {
        this._userVideoMedia = userVideoMedia;
    }
    get userAudioTrack() {
        return this._userAudioTrack;
    }
    set userAudioTrack(userAudioTrack) {
        if (this._userAudioTrack) {
            this._userAudioTrack.stop();
        }
        this._userAudioTrack = userAudioTrack;
    }
    get userVideoTrack() {
        return this._userVideoTrack;
    }
    set userVideoTrack(userVideoTrack) {
        if (this._userVideoTrack) {
            this._userVideoTrack.stop();
        }
        if (this._intervalId) {
            clearInterval(this._intervalId);
            this._secBeforeNextNSFWCheck = NSFW_CHECK_TIMEOUT_SEC;
        }
        this._userVideoTrack = userVideoTrack;
        this.generatePredictions();
        this._intervalId = setInterval(() => {
            runInAction(() => {
                if (this._secBeforeNextNSFWCheck <= 0) {
                    this.generatePredictions();
                    this._secBeforeNextNSFWCheck = NSFW_CHECK_TIMEOUT_SEC;
                }
                this._secBeforeNextNSFWCheck -= 1;
            });
        }, 1000);
    }
    get secBeforeNextNSFWCheck() {
        return this._secBeforeNextNSFWCheck;
    }
    get userAudioActive() {
        return this._userAudioActive;
    }
    set userAudioActive(isActive) {
        this._userAudioActive = isActive;
    }
    get userVideoBackgroundImg() {
        return this._userVideoBackgroundImg;
    }
    set userVideoBackgroundImg(userVideoBackgroundImg) {
        this._userVideoBackgroundImg = userVideoBackgroundImg;
    }
    get userVideoActive() {
        return this._userVideoActive;
    }
    set userVideoActive(isActive) {
        this._userVideoActive = isActive;
    }
    get nsfwPredictions() {
        return this._nsfwPredictions;
    }
    setAudioActive = (active) => {
        if (!this._userAudioTrack || this._userAudioActive === active) {
            return;
        }
        this._userAudioTrack.enabled = active;
        this._userAudioActive = active;
    };
    setVideoActive = (active) => {
        if (!this._userVideoTrack || this._userVideoActive === active) {
            return;
        }
        this._userVideoTrack.enabled = active;
        this._userVideoActive = active;
    };
    toggleAudioMuted = () => {
        if (!this._userAudioTrack) {
            return;
        }
        const isEnabled = this._userAudioTrack.enabled;
        this._userAudioTrack.enabled = !isEnabled;
        this._userAudioActive = !isEnabled;
        sendInteraction({
            method: SendInteractionsMessages.mute,
            message: {
                userId: userStore.user.id,
                muted: isEnabled,
            },
        });
    };
    toggleVideoMuted = () => {
        if (!this._userVideoTrack) {
            return;
        }
        const isEnabled = this._userVideoTrack.enabled;
        this._userVideoTrack.enabled = !isEnabled;
        this._userVideoActive = !isEnabled;
        sendInteraction({
            method: SendInteractionsMessages.videoMute,
            message: {
                userId: userStore.user.id,
                muted: isEnabled,
            },
        });
    };
    clear = () => {
        this.userAudioTrack = null;
        this.userVideoTrack = null;
        this._userAudioDeviceId = null;
        this._userVideoDeviceId = null;
    };
    generatePredictions = async () => {
        if (!this._userVideoTrack || this._isNSFWCheckRunning) {
            return;
        }
        this._secBeforeNextNSFWCheck = NSFW_CHECK_TIMEOUT_SEC;
        const videoStream = new MediaStream();
        videoStream.addTrack(this._userVideoTrack);
        const video = document.createElement('video');
        const { width, height } = this._userVideoTrack?.getSettings() ?? {};
        video.width = width ?? 0;
        video.height = height ?? 0;
        video.srcObject = videoStream;
        video.onplaying = async () => {
            const screenshot = (await getVideoScreenshot(video));
            const predictions = await ExplicitContentService.checkExplicitContent({ image: screenshot });
            if (!predictions || isAxiosError(predictions)) {
                // eslint-disable-next-line no-console
                console.log('Error detecting explicit content');
                return;
            }
            runInAction(() => {
                this._nsfwPredictions = predictions.data;
                this._lastNSFWCheckImageBase64 = screenshot;
                this._isNSFWCheckRunning = false;
                video.remove();
            });
        };
        video.play();
    };
}
export const userMediaStore = new UserMediaStore();
