import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentActions, ContentDescription, ContentHeading, ModalContent } from './styled';
import { modalStore, roomModalStore } from '@/shared/model/modal';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import { ROUTES } from '@/shared/constants/routes';
import { useRoomDisconnect } from '@/shared/lib/hooks';
const SpeedDatingStartedNotification = ({ roomId, }) => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const handleClose = () => {
        modalStore.close();
        roomModalStore.close();
    };
    const handleMoveToSpeedDating = () => {
        modalStore.close();
        roomModalStore.close();
        (async () => {
            await disconnect();
            navigate(`${ROUTES.rooms.path}/${roomId}`);
        })();
    };
    return (_jsxs(ModalContent, { children: [_jsx(ContentHeading, { children: "Speed Dating event begins now!" }), _jsxs(ContentDescription, { children: ["Our speed dating event is starting now!", _jsx("br", {}), "Are you ready to enter the event room and discover an exciting date tailored to your preferences?"] }), _jsxs(ContentActions, { children: [_jsx(ButtonCustom, { variant: 'outlined', onClick: handleClose, height: 48, width: 162, children: "Nevermind" }), _jsx(ButtonCustom, { variant: 'contained', onClick: handleMoveToSpeedDating, height: 48, width: 162, children: "Yes, move" })] })] }));
};
export default memo(SpeedDatingStartedNotification);
