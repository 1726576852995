import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, DescriptionField, ReportReason, ReportReasonsWrapper, CloseButton } from './styled';
import { reportUser } from './api';
import { modalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import ListEnhanced from '@/shared/ui/ListEnhanced';
import SubmitButton from '@/shared/ui/SubmitButton';
import TextElement from '@/shared/ui/TextElement';
import { CloseIcon } from '@/shared/assets/icons';
export var ReportType;
(function (ReportType) {
    ReportType["CHAT"] = "CHAT";
    ReportType["VIDEO"] = "VIDEO";
})(ReportType || (ReportType = {}));
const videoReportReasons = [
    {
        id: 1,
        reason: 'Inappropriate video content',
    },
    {
        id: 2,
        reason: 'Made me uncomfortable',
    },
    {
        id: 3,
        reason: 'They asked me for money',
    },
];
const chatReportReasons = [
    {
        id: 1,
        reason: 'Inappropriate message content',
    },
    {
        id: 2,
        reason: 'Made me uncomfortable',
    },
    {
        id: 3,
        reason: 'They asked me for money',
    },
];
const maxLength = 250;
const ReportForm = observer(({ reportedId, roomId, attachment = null, reportType = ReportType.VIDEO, }) => {
    const [reportReason, setReportReason] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const { user } = userStore;
    const reportReasons = useMemo(() => {
        if (reportType === ReportType.CHAT) {
            return chatReportReasons;
        }
        return videoReportReasons;
    }, [reportType]);
    const handleChange = useCallback((event) => {
        setDescription(event.target.value);
    }, []);
    const handleClick = useCallback((reason) => () => {
        setReportReason(reason);
    }, []);
    const handleClose = useCallback(() => {
        modalStore.close(() => {
            setStep(1);
            setDescription('');
            setReportReason('');
        });
    }, []);
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (step === 3) {
            handleClose();
            return;
        }
        if (step === 2) {
            setLoading(true);
            await reportUser({
                reporterId: user.id,
                reportedId,
                reason: reportReason,
                description,
                attachment,
                roomId,
            });
            setLoading(false);
        }
        setStep((prev) => prev + 1);
    }, [attachment, description, handleClose, reportReason, reportedId, roomId, step, user.id]);
    return (_jsxs(Form, { onSubmit: handleSubmit, children: [_jsx(CloseButton, { onClick: handleClose, "aria-label": 'Close', children: _jsx(CloseIcon, {}) }), _jsx(TextElement, { variant: 'h6', textAlign: 'center', fontSize: 24, fontWeight: 600, children: step === 3 ? 'Thank you for keeping it safe' : 'What is the report reason?' }), step === 3 ? (_jsxs(_Fragment, { children: [_jsx(TextElement, { fontSize: 16, textAlign: 'center', children: "Thank you for helping us to keep our space safe for all users. Rest assured, we'll handle your report swiftly and effectively." }), _jsx(ButtonCustom, { type: 'submit', maxWidth: 176, height: 48, variant: 'contained', children: "You\u2019re welcome" })] })) : (_jsxs(_Fragment, { children: [_jsxs(ReportReasonsWrapper, { children: [_jsx(TextElement, { fontSize: 16, paddingLeft: '12px', children: step === 1
                                    ? 'Please choose the reason for your report'
                                    : 'Tell us what happened. Don’t worry, we won’t tell.' }), step === 1 ? (_jsx(ListEnhanced, { gap: '6px', sx: { width: '100%' }, children: reportReasons.map(({ id, reason }) => (_jsx("li", { children: _jsx(ReportReason, { onClick: handleClick(reason), className: reason === reportReason ? 'selected' : undefined, "aria-selected": reason === reportReason, children: reason }) }, id))) })) : (_jsx(DescriptionField, { fullWidth: true, "$isMaxLength": description.length === maxLength, name: 'description', variant: 'outlined', inputProps: { maxLength }, helperText: `${description.length}/${maxLength}`, minRows: 3, multiline: true, autoFocus: true, onChange: handleChange }))] }), _jsx(SubmitButton, { loading: loading, disabled: step === 1 ? !reportReason : !description, maxWidth: step === 1 ? 122 : 159, height: 48, variant: 'contained', children: step === 1 ? 'Continue' : 'Submit report' })] }))] }));
});
export default memo(ReportForm);
