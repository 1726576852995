import { makeAutoObservable } from 'mobx';
import { STORAGE_KEYS } from '@/shared/constants/common';
import StorageService from '@/shared/lib/utils/storage';
class UserPreferencesStore {
    _stageVolume = 10;
    _isStageZoomed = false;
    _showVideoSDKVideoStatus = !!StorageService.get(STORAGE_KEYS.showVideoSDKVideoStatus, false);
    _showExplicitContentCheck = !!StorageService.get(STORAGE_KEYS.showExplicitContentCheck, false);
    _roomBackgroundMusicVolume = +(StorageService.get(STORAGE_KEYS.backgroundMusicVolume) ?? 0.125);
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get stageVolume() {
        return this._stageVolume;
    }
    set stageVolume(stageVolume) {
        this._stageVolume = stageVolume;
    }
    get roomBackgroundMusicVolume() {
        return this._roomBackgroundMusicVolume;
    }
    set roomBackgroundMusicVolume(volume) {
        this._roomBackgroundMusicVolume = volume;
        StorageService.set(STORAGE_KEYS.backgroundMusicVolume, volume);
    }
    get showVideoSDKVideoStatus() {
        return this._showVideoSDKVideoStatus;
    }
    set showVideoSDKVideoStatus(isShow) {
        this._showVideoSDKVideoStatus = isShow;
        if (isShow) {
            StorageService.set(STORAGE_KEYS.showVideoSDKVideoStatus, isShow);
        }
        else {
            StorageService.remove(STORAGE_KEYS.showVideoSDKVideoStatus);
        }
    }
    get showExplicitContentCheck() {
        return this._showExplicitContentCheck;
    }
    set showExplicitContentCheck(isShow) {
        this._showExplicitContentCheck = isShow;
        if (isShow) {
            StorageService.set(STORAGE_KEYS.showExplicitContentCheck, isShow);
        }
        else {
            StorageService.remove(STORAGE_KEYS.showExplicitContentCheck);
        }
    }
    get isStageZoomed() {
        return this._isStageZoomed;
    }
    set isStageZoomed(isZoomed) {
        this._isStageZoomed = isZoomed;
    }
    toggleIsZoomed() {
        this._isStageZoomed = !this._isStageZoomed;
    }
}
export const userPreferencesStore = new UserPreferencesStore();
