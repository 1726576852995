import styled from 'styled-components';
export const Background = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 16px;
`;
export const Container = styled.div `
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Text = styled.div `
  position: absolute;
  width: 100%;
  bottom: calc(100% + 32px);
  text-align: center;
  opacity: 1;

  color: ${({ theme }) => theme.palette.primary.main};
  animation: fade-in 2s ease-in-out infinite;

  font-size: 32px;
  line-height: 40px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 32px;
  }

  ${({ theme }) => theme.breakpoints.down(350)} {
    font-size: 20px;
    line-height: 24px;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
