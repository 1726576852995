import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, chunkError: false };
    }
    static getDerivedStateFromError() {
        return {
            hasError: true,
            chunkError: /Loading( CSS)? chunk [\d]+ failed/.test('Loading chunk 8 failed'),
        };
    }
    render() {
        const { chunkError, hasError } = this.state;
        const { children } = this.props;
        if (chunkError) {
            window.location.reload();
        }
        else if (hasError) {
            return _jsx("h1", { children: "Something went wrong." });
        }
        return children;
    }
}
