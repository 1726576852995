import { makeAutoObservable } from 'mobx';
import { friendStore } from '@/shared/model/friends';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { popSound } from '@/shared/assets/sounds';
import { ViewMode } from '@/shared/constants/viewMode';
class HeaderStore {
    _chatListOpened = false;
    _showControls = false;
    _friendChatOpened = false;
    _friendsListOpened = false;
    _friendRequestsOpened = false;
    _viewMode = ViewMode.DEFAULT;
    _chatMessageReceived = false;
    _friendRequestReceived = false;
    _unreadMessagesCount = {
        tableChat: 0,
        friendsChats: [],
    };
    _showIds = false;
    _showCameraControl = false;
    constructor() {
        makeAutoObservable(this);
    }
    set chatMessageReceived(chatMessageReceived) {
        if (chatMessageReceived && this._chatListOpened)
            return;
        if (chatMessageReceived) {
            if (StorageService.get(STORAGE_KEYS.messageSound, false) === 'false')
                return;
            const audio = new Audio(popSound);
            audio.play();
        }
        if (this._chatMessageReceived !== chatMessageReceived) {
            this._chatMessageReceived = chatMessageReceived;
        }
    }
    get chatMessageReceived() {
        return this._chatMessageReceived;
    }
    playReceivedAudio = () => {
        if (this._friendChatOpened)
            return;
        const audio = new Audio(popSound);
        audio.play();
    };
    get friendRequestReceived() {
        return this._friendRequestReceived;
    }
    set viewMode(viewMode) {
        if (this._viewMode !== viewMode) {
            this._viewMode = viewMode;
        }
    }
    get viewMode() {
        return this._viewMode;
    }
    get friendRequestsOpened() {
        return this._friendRequestsOpened;
    }
    set friendRequestsOpened(friendRequestsOpened) {
        this._friendRequestsOpened = friendRequestsOpened;
    }
    set friendChatOpened(chatOpened) {
        if (chatOpened) {
            this._friendChatOpened = chatOpened;
            return;
        }
        this._friendChatOpened = chatOpened;
    }
    get friendChatOpened() {
        return this._friendChatOpened;
    }
    set chatListOpened(chatOpened) {
        if (chatOpened) {
            this._friendsListOpened = false;
            this._chatMessageReceived = false;
        }
        else {
            friendStore.activeFriendChatId = 0;
        }
        this._chatListOpened = chatOpened;
    }
    get chatListOpened() {
        return this._chatListOpened;
    }
    get anyChatOpened() {
        return this._chatListOpened || this._friendsListOpened;
    }
    closeAllChats = () => {
        friendStore.activeFriendChatId = 0;
        this._chatListOpened = false;
        this.friendsListOpened = false;
    };
    set friendsListOpened(friendsListOpened) {
        if (!friendsListOpened && this._friendsListOpened) {
            friendStore.newFriends = [];
        }
        if (friendsListOpened) {
            this._chatListOpened = false;
        }
        else {
            friendStore.activeFriendChatId = 0;
        }
        this._friendsListOpened = friendsListOpened;
    }
    get friendsListOpened() {
        return this._friendsListOpened;
    }
    increaseUnreadMessageCount(chatId) {
        if (chatId !== undefined) {
            this._updateFriendChatCount(chatId, 1);
        }
        else {
            this._updateTableChat(1);
        }
    }
    cleanUnreadMessageCount(chatId) {
        if (chatId !== undefined) {
            this._updateFriendChatCount(chatId, 0, true);
        }
        else {
            this._updateTableChat(0);
        }
    }
    _updateTableChat(increment) {
        this._unreadMessagesCount = {
            ...this._unreadMessagesCount,
            tableChat: increment === 0 ? 0 : this._unreadMessagesCount.tableChat + increment,
        };
    }
    _updateFriendChatCount(chatId, count, isReset = false) {
        const existChatIndex = this._unreadMessagesCount.friendsChats.findIndex((chat) => chat.id === chatId);
        if (existChatIndex !== -1) {
            this._unreadMessagesCount = {
                ...this._unreadMessagesCount,
                friendsChats: this._unreadMessagesCount.friendsChats.map((chat, index) => index === existChatIndex ? { ...chat, count: isReset ? 0 : chat.count + count } : chat),
            };
        }
        else if (!isReset) {
            this._unreadMessagesCount = {
                ...this._unreadMessagesCount,
                friendsChats: [...this._unreadMessagesCount.friendsChats, { id: chatId, count }],
            };
        }
    }
    get unreadMessagesCount() {
        return this._unreadMessagesCount;
    }
    get showControls() {
        return this._showControls;
    }
    set showControls(show) {
        this._showControls = show;
    }
    get showIds() {
        return this._showIds;
    }
    set showIds(show) {
        this._showIds = show;
    }
    get showCameraControl() {
        return this._showCameraControl;
    }
    set showCameraControl(showCameraControl) {
        this._showCameraControl = showCameraControl;
    }
    toggleShowIds = () => {
        this._showIds = !this._showIds;
        return this._showIds;
    };
    clear() {
        this._showCameraControl = false;
        this._showControls = false;
        this._showIds = false;
    }
}
export const headerStore = new HeaderStore();
