import { List } from '@mui/material';
import styled, { css } from 'styled-components';
export const RequestsCounter = styled.span `
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  padding: 0 12px;
  height: 24px;
  border-radius: 8px;

  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(14)};
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.primary.main};
  `}
`;
export const FriendRequestsWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const FriendsList = styled(List) `
  overflow-y: auto;
  border-radius: 0;
  position: relative;
  width: 100%;

  margin-right: -5px;
  flex-grow: 1;
  padding-right: 8px;

  ${({ $isLobby }) => css `
    &::-webkit-scrollbar {
      width: 5px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      ${({ theme }) => css `
        border-radius: 5px;
        background: ${theme.palette.primary.main};
      `}
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      margin-bottom: 10px;
    }

    ${$isLobby
    ? css `
          &::-webkit-scrollbar-thumb {
            ${({ theme }) => css `
              background: ${theme.palette.primary.main};
            `}
          }

          &::-webkit-scrollbar-track {
            background: #e2e2e2;
          }
        `
    : css `
          &::-webkit-scrollbar-thumb {
            background: #a2a2bf;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }
        `}
  `}
`;
export const EmptyListWrapper = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  margin-top: 100px;
  color: #292d32;
`;
export const EmptyListTitle = styled.p `
  margin: 0;
  padding: 0;
  text-align: center;

  ${({ theme }) => css `
    color: ${theme.palette.grey[600]};
    font-size: ${theme.util.pxToRem(14)};
    font-weight: bold;
  `}
`;
export const EmptyListText = styled.p `
  margin: 0;
  padding: 0;
  text-align: center;

  ${({ theme }) => css `
    color: ${theme.palette.grey[600]};
    font-size: ${theme.util.pxToRem(14)};
    font-weight: 400;
  `}
`;
