import styled from 'styled-components';
export const ModalContent = styled.section `
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100dvw;
  max-width: min(${({ width = '576px' }) => width}, calc(100dvw - 16px));
  outline: none;
`;
export const ContentHeading = styled.h1 `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const ContentDescription = styled.p `
  max-width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`;
export const ContentActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
`;
