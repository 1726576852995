import imageCompression from 'browser-image-compression';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isAxiosError } from 'axios';
import { getIsExplicitContent } from '@/shared/utils/getIsExplicitContent';
import ExplicitContentService from '@/shared/api/services/ExplicitContentService';
export const toBytes = (mb) => mb * 1024 * 1024;
export const toMBs = (bytes) => bytes / 1024 / 1024;
const usePictureUploader = (onMaxSize, onExplicitContent, { maxSizeMB = 10, defaultFileData = null } = {}) => {
    const [fileData, setFileData] = useState(defaultFileData);
    const [key, setKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const savedOnMaxSizeCallback = useRef(null);
    const savedOnExplicitContentCallback = useRef(null);
    useEffect(() => {
        savedOnMaxSizeCallback.current = onMaxSize;
    }, [onMaxSize]);
    useEffect(() => {
        savedOnExplicitContentCallback.current = onExplicitContent;
    }, [onExplicitContent]);
    const handleFileReset = useCallback(() => {
        setFileData(null);
        setKey((prevKey) => prevKey + 1);
    }, []);
    const handleFileChange = useCallback(async (e) => {
        if (!e.target.files) {
            return;
        }
        const maxSize = toBytes(maxSizeMB);
        const image = e.target.files[0];
        if (!image) {
            return;
        }
        if (image.size >= maxSize) {
            savedOnMaxSizeCallback.current?.(maxSizeMB);
            return;
        }
        setLoading(true);
        setFileData(null);
        const sizePercentage = (image.size / maxSize) * 100;
        const maxClamp = 60;
        const minClamp = 0;
        const compressionPercentageUnclamped = maxClamp - sizePercentage / 4;
        const compressionPercentage = Math.min(Math.max(compressionPercentageUnclamped, minClamp), maxClamp) / 100;
        const options = {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            maxIteration: 30,
            quality: compressionPercentage,
        };
        const compressedImage = await imageCompression(image, options);
        const reader = new FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onload = async (event) => {
            const data = event.target?.result?.toString();
            const splitData = data?.split(';base64,');
            if (data) {
                const img = document.createElement('img');
                img.src = data;
                const isExplicitContent = await new Promise((res) => {
                    setTimeout(async () => {
                        const predictions = await ExplicitContentService.checkExplicitContent({
                            image: data,
                        });
                        if (!predictions || isAxiosError(predictions)) {
                            // eslint-disable-next-line no-console
                            console.log('Error getting predictions');
                            return res(true);
                        }
                        res(getIsExplicitContent(predictions.data));
                    }, 200);
                });
                if (isExplicitContent) {
                    setLoading(false);
                    savedOnExplicitContentCallback.current?.();
                    return;
                }
            }
            setFileData({
                format: splitData?.[0],
                data,
            });
            setLoading(false);
        };
    }, [maxSizeMB]);
    return { key, fileData, handleFileChange, handleFileReset, setFileData, loading };
};
export default usePictureUploader;
