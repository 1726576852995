import { createGlobalStyle, css } from 'styled-components';
export const GlobalStyle = createGlobalStyle `
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
  }

  * {
    box-sizing: border-box;
  }

  body {
    ${({ theme }) => css `
      font-family: ${theme.font.family.primary};
      background-color: ${theme.palette.backgroundPrimary.main};
      overflow-x: hidden;

      * {
        margin: 0;
        padding: 0;
        font-family: ${theme.font.family.primary};
        text-align: start;
        unicode-bidi: plaintext;
      }

      .PrivatePickersYear-yearButton {
        text-align: center;
      }
    `};
  }

  .container {
    padding: 0;
    min-height: fit-content;

    ${({ theme }) => css `
      max-width: 1250px;

      @media screen and (max-width: 1350px) {
        padding: 0 32px;
      }

      ${theme.breakpoints.down('md')} {
        padding: 0 16px;
      }
    `}
  }

	ul, li {
		list-style: none;
    margin: 0;
    padding: 0;
	}

  button {
    cursor: pointer;
  }

  .MuiList-root {
    & * {
      justify-content: left;
      text-align: left;
    }
  }

  :root {
    --regular-table-width: 276px;

    --stage-table-padding: 8px;
    --stage-table-height: 300px;
    --stage-table-left-margin: 7%;

    @media screen and (max-width: 1350px) {
      --regular-table-width: 212px;
    }

    @media screen and (max-width: 1180px) {
      --regular-table-width: 184px;
    }

  }

  @keyframes heartbeat {
        0%,
        50%, 100% {
          scale: 1;
        }
        25%, 75% {
          scale: 1.2;
        }
      }
`;
