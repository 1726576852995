import { convertBlobToBase64 } from './converBlobToBase64';
/**
 * Get screenshot of the video converted to base64
 */
export const getVideoScreenshot = async (video) => {
    const canvas = document.createElement('canvas');
    canvas.width = video.width ?? 0;
    canvas.height = video.height ?? 0;
    const ctx = canvas.getContext('2d');
    return new Promise((resolve) => {
        if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(async (blob) => {
                if (blob) {
                    resolve(convertBlobToBase64(blob));
                }
            }, 'image/jpeg', 0.1);
        }
    });
};
