import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import useConnectToNotifications from './lib/hooks/useConnectToNotifications';
import useVerifyUser from './lib/hooks/useVerifyUser';
import { ROUTES } from '@/shared/constants/routes';
import { userStore } from '@/shared/model/user';
import { ADMIN } from '@/shared/config/admin';
import { RoomLoader } from '@/entities/room-loader';
const ProtectedRoute = observer(() => {
    const params = useParams();
    const [verifying, verified] = useVerifyUser();
    useConnectToNotifications(verified);
    if (verifying) {
        return _jsx(RoomLoader, {});
    }
    if (!verified) {
        return _jsx(Navigate, { to: ROUTES.home.path, replace: true });
    }
    const { user } = userStore;
    const roomId = Number(params.roomId);
    if (!user.isAdmin && roomId === ADMIN.roomId) {
        return _jsx(Navigate, { to: ROUTES.rooms.path, replace: true });
    }
    return _jsx(Outlet, {});
});
export default memo(ProtectedRoute);
