import { Avatar, List, ListItemButton } from '@mui/material';
import styled, { css } from 'styled-components';
export const UserAvatar = styled(Avatar) `
  height: 46px;
  width: 46px;
  background: #ffffff;
`;
export const OptionsList = styled(List) `
  ${({ theme }) => css `
    & li {
      padding: 0;
    }

    & li button {
      font-size: ${theme.util.pxToRem(15)};
    }
  `}
`;
export const ItemButton = styled(ListItemButton).attrs(() => ({
    component: 'button',
})) `
  white-space: nowrap;
`;
