import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@mui/material';
import { AdministrationFormContent, Title } from './styled';
import { userPreferencesStore } from '@/shared/model/user-preferences';
export const AdministrationForm = observer(() => {
    const { showVideoSDKVideoStatus, showExplicitContentCheck } = userPreferencesStore;
    const handleIsShowVideoSDKStatusChange = useCallback((_, checked) => {
        userPreferencesStore.showVideoSDKVideoStatus = checked;
    }, []);
    const handleIsShowExplicitContentCheckChange = useCallback((_, checked) => {
        userPreferencesStore.showExplicitContentCheck = checked;
    }, []);
    return (_jsxs(AdministrationFormContent, { children: [_jsx(Title, { marginBottom: '12px', children: "Administration" }), _jsxs("div", { children: ["Show VideoSDK status button:", _jsx(Checkbox, { checked: showVideoSDKVideoStatus, onChange: handleIsShowVideoSDKStatusChange })] }), _jsxs("div", { children: ["Show explicit content check:", _jsx(Checkbox, { checked: showExplicitContentCheck, onChange: handleIsShowExplicitContentCheckChange })] })] }));
});
