import { useState, useEffect } from 'react';
import { headerStore } from '@/shared/model/header';
import { friendStore } from '@/shared/model/friends';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
const useFriendsNotificationsCounter = () => {
    const { friendsListOpened } = headerStore;
    const { invites, friends, newFriends } = friendStore;
    const [counter, setCounter] = useState(0);
    const [isMax, setIsMax] = useState(false);
    const [playSound, setPlaySound] = useState(false);
    useEffect(() => {
        const max = 100;
        const requests = friends.filter((friend) => !friend.accepted);
        const quantity = requests.length + invites.length + newFriends.length;
        const isOver = quantity >= max;
        setCounter((prev) => {
            const newCounter = isOver ? 99 : quantity;
            if (newCounter !== prev) {
                setPlaySound(true);
            }
            return newCounter;
        });
        setIsMax(isOver);
    }, [friends, invites, newFriends]);
    useEffect(() => {
        if (playSound) {
            if (counter > 0 && !friendsListOpened) {
                const friendsRequest = +(StorageService.get(STORAGE_KEYS.friendsRequest, false) ?? 0);
                if (friendsRequest !== counter) {
                    headerStore.playReceivedAudio();
                    StorageService.set(STORAGE_KEYS.friendsRequest, counter);
                }
            }
            setPlaySound(false);
        }
    }, [counter, friendsListOpened, playSound]);
    return [isMax, counter];
};
export default useFriendsNotificationsCounter;
