import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { OptionsList, UserAvatar } from './styled';
import { ProfileOption } from './ProfileOption';
import { HelpOption } from './HelpOption';
import { SettingsAndPrivacyOption } from './SettingsAndPrivacyOption';
import { ReportBugOption } from './ReportBugOption';
import { SignOutOption } from './SignOutOption';
import { userStore } from '@/shared/model/user';
import { usePopoverAnchor } from '@/shared/lib/hooks';
import { http } from '@/shared/api/http';
import { ROLES } from '@/shared/constants/roles';
import { avatarPlaceholder } from '@/shared/assets/images';
const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};
export const UserProfile = observer(() => {
    const { user } = userStore;
    const { role, profilePicture } = user;
    const src = role === ROLES.user
        ? profilePicture
        : `${http.getImagesUrl()}/UsersPerformers/${profilePicture}`;
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { size: 'small', onClick: handleOpen, children: _jsx(UserAvatar, { alt: 'You', src: profilePicture ? src : avatarPlaceholder }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, children: _jsxs(OptionsList, { children: [_jsx(ProfileOption, { onClose: handleClose }), _jsx(HelpOption, { onClose: handleClose }), _jsx(SettingsAndPrivacyOption, { onClose: handleClose }), user.isAdmin && _jsx(ReportBugOption, { onClose: handleClose }), _jsx(SignOutOption, {})] }) })] }));
});
