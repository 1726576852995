import styled, { css } from 'styled-components';
export const Content = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-radius: 8px;
  outline: none;

  ${({ theme, isRemoveBg }) => !isRemoveBg &&
    css `
      background-color: ${theme.palette.white.main};
    `}
`;
