import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ListItem } from '@mui/material';
import { memo } from 'react';
import { AutocompleteStyled, PaperStyled } from './styled';
import InputFormField from '../InputFormField';
import countryOptions from '@/shared/assets/db/countries.json';
const CountryPicker = ({ value, popupIcon, fullWidth, onAutoCompleteChange, disabled, TextField = InputFormField, defaultValue, ...props }) => {
    const handleChange = (_, data) => {
        onAutoCompleteChange?.(data?.name.common);
    };
    return (_jsx(AutocompleteStyled, { popupIcon: popupIcon, onChange: handleChange, disabled: disabled, isOptionEqualToValue: (option, optValue) => option.name.common === optValue.name.common, getOptionLabel: (option) => option.name.common, options: countryOptions, renderOption: (renderProps, option) => (_jsx(ListItem, { ...renderProps, children: `${option.flag} ${option.name.common}` })), PaperComponent: PaperStyled, renderInput: (params) => (_jsx(TextField, { ...props, value: value, ...params, fullWidth: fullWidth, disabled: disabled, InputProps: {
                ...params.InputProps,
                endAdornment: _jsx(_Fragment, { children: params.InputProps.endAdornment }),
            } })), defaultValue: defaultValue }));
};
export default memo(CountryPicker);
