export var MetricType;
(function (MetricType) {
    // View metrics
    MetricType["HOMEPAGE_VIEW"] = "HOMEPAGE_VIEW";
    MetricType["SIGNUP_VIEW"] = "SIGNUP_VIEW";
    // Click metrics
    MetricType["JOIN_ON_DESKTOP_CLICK"] = "JOIN_ON_DESKTOP_CLICK";
    MetricType["SEND_EMAIL_REMAINDER_CLICK"] = "SEND_EMAIL_REMAINDER_CLICK";
    MetricType["SIGN_UP_COMPLETED"] = "SIGN_UP_COMPLETED";
})(MetricType || (MetricType = {}));
