import axiosInstance from '../http';
import { env } from '@/shared/config';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
// To do not flood the prod metrics server instead of sending metrics just show log of it in dev mode
const sendMetricsNoop = async ({ type, meta, }) => {
    // eslint-disable-next-line no-console
    console.log('Metrics send:', { type, meta });
    return { data: {} };
};
const sendMetrics = async ({ type, meta, }) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.metrics, {
        type,
        meta,
    });
    return response;
});
export const MetricsService = env.mode === 'production' ? { sendMetrics } : { sendMetrics: sendMetricsNoop };
