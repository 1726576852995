import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useRoomDisconnect from './useRoomDisconnect';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore, roomModalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
import { headerStore } from '@/shared/model/header';
import { friendStore } from '@/shared/model/friends';
import { connectionStore } from '@/shared/model/connection';
const useSignOut = () => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const signOut = useCallback(async () => {
        modalStore.close();
        roomModalStore.close();
        await disconnect();
        await connectionStore.notificationHubConnection?.stop();
        connectionStore.notificationHubConnection = null;
        userStore.logout();
        headerStore.closeAllChats();
        friendStore.clear();
        navigate(ROUTES.home.path, { replace: true });
    }, [disconnect, navigate]);
    return { signOut };
};
export default useSignOut;
