import { makeAutoObservable, runInAction } from 'mobx';
import { isAxiosError } from 'axios';
import RoomService from '@/shared/api/services/RoomService';
export const EVENT_ROOM_ID = 58;
export var RoomType;
(function (RoomType) {
    RoomType["REGULAR"] = "REGULAR";
    RoomType["EVENT"] = "EVENT";
    RoomType["SPEED_DATING"] = "SPEED_DATING";
})(RoomType || (RoomType = {}));
const emptyRoom = {
    countSeats: 0,
    countTables: 0,
    countUsers: 0,
    id: 0,
    roomName: '',
    theme: 'default',
    maxCapacity: 0,
    image: '',
    sexualOrientation: '',
    description: '',
    isSpeedDating: false,
    isReoccurring: false,
    openDate: '',
    question: '',
    closed: false,
};
class RoomStore {
    _room = emptyRoom;
    _roomType = RoomType.REGULAR;
    _isEventStarted = false;
    _isStealthMode = false;
    _roomTheme = 'default';
    _preLogin = true;
    _tables = [];
    _loading = true;
    _stageQueue = [];
    _isPerformer = false;
    _eventData = null;
    _eventStarted = false;
    _isRoomWithControls = true;
    _question = '';
    _barTables = [];
    _regularTables = [];
    _stageTable = null;
    _users = [];
    _newUsers = [];
    _leftUsers = [];
    _newUserSeatEl = null;
    _questionAnswers = [];
    _visibleUsers = [];
    _isStageDisplayed = false;
    _clappingUsersId = [];
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set room(room) {
        if (!room)
            return;
        this._room = room;
    }
    get room() {
        return this._room;
    }
    set tables(tables) {
        if (!tables)
            return;
        this._tables = tables;
    }
    get tables() {
        return this._tables;
    }
    set questionAnswers(answers) {
        this._questionAnswers = answers;
    }
    get questionAnswers() {
        return this._questionAnswers;
    }
    set barTables(barTables) {
        if (!barTables)
            return;
        this._barTables = barTables;
    }
    get barTables() {
        return this._barTables;
    }
    set regularTables(regularTables) {
        if (!regularTables)
            return;
        this._regularTables = regularTables;
    }
    get regularTables() {
        return this._regularTables;
    }
    set stageTable(stageTable) {
        if (!stageTable)
            return;
        this._stageTable = stageTable;
    }
    get stageTable() {
        return this._stageTable;
    }
    set preLogin(preLogin) {
        this._preLogin = preLogin;
    }
    get preLogin() {
        return this._preLogin;
    }
    set roomTheme(theme) {
        if (this._roomTheme !== theme) {
            this._roomTheme = theme;
        }
    }
    get roomTheme() {
        return this._roomTheme;
    }
    set stageQueue(stageQueue) {
        this._stageQueue = stageQueue;
    }
    get stageQueue() {
        return this._stageQueue;
    }
    set isRoomWithControls(isRoomWithControls) {
        this._isRoomWithControls = isRoomWithControls;
    }
    get isRoomWithControls() {
        return this._isRoomWithControls;
    }
    set eventData(eventData) {
        this._eventData = eventData;
    }
    get eventData() {
        return this._eventData;
    }
    set loading(loading) {
        this._loading = loading;
    }
    get loading() {
        return this._loading;
    }
    set isPerformer(isPerformer) {
        this._isPerformer = isPerformer;
    }
    get isPerformer() {
        return this._isPerformer;
    }
    set eventStarted(eventStarted) {
        this._eventStarted = eventStarted;
    }
    get eventStarted() {
        return this._eventStarted;
    }
    set question(question) {
        this._question = question;
    }
    get question() {
        return this._question;
    }
    set users(users) {
        this._users = users;
    }
    get users() {
        return this._users;
    }
    set isStageDisplayed(isStageDisplayed) {
        this._isStageDisplayed = isStageDisplayed;
    }
    get isStageDisplayed() {
        return this._isStageDisplayed;
    }
    set visibleUsers(visibleUsers) {
        this._visibleUsers = visibleUsers;
    }
    get visibleUsers() {
        return this._visibleUsers;
    }
    set newUsers(newUsers) {
        this._newUsers = newUsers;
    }
    get newUsers() {
        return this._newUsers;
    }
    set leftUsers(leftUsers) {
        this._leftUsers = leftUsers;
    }
    get leftUsers() {
        return this._leftUsers;
    }
    set newUserSeatEl(newUserSeatEl) {
        this._newUserSeatEl = newUserSeatEl;
    }
    get newUserSeatEl() {
        return this._newUserSeatEl;
    }
    set isStealthMode(isStealthMode) {
        this._isStealthMode = isStealthMode;
    }
    get isStealthMode() {
        return this._isStealthMode;
    }
    get clappingUsersId() {
        return this._clappingUsersId;
    }
    addClappingUser(userId) {
        if (this._clappingUsersId.find((u) => u === userId)) {
            return;
        }
        this._clappingUsersId = [...this._clappingUsersId, userId];
    }
    removeClappingUserById(userId) {
        this._clappingUsersId = this._clappingUsersId.filter((u) => u !== userId);
    }
    set isEventStarted(isEventStarted) {
        this._isEventStarted = isEventStarted;
    }
    get isEventStarted() {
        return this._isEventStarted;
    }
    get roomType() {
        return this._roomType;
    }
    init = async (roomId) => {
        const response = await RoomService.getRoomDetails(roomId);
        if (response && !isAxiosError(response)) {
            return runInAction(() => {
                const getRoomType = () => {
                    if (response.data.isSpeedDating) {
                        return RoomType.SPEED_DATING;
                    }
                    if (response.data.id === EVENT_ROOM_ID) {
                        return RoomType.EVENT;
                    }
                    return RoomType.REGULAR;
                };
                this._room = {
                    ...response.data,
                };
                this._roomType = getRoomType();
            });
        }
        // eslint-disable-next-line no-console
        console.log('Error fetching room details:', response);
        await this.init(roomId);
    };
    clear = () => {
        this._room = emptyRoom;
        this._preLogin = true;
        this._tables = [];
        this._barTables = [];
        this._regularTables = [];
        this._stageTable = null;
        this.users = [];
        this._newUsers = [];
        this._leftUsers = [];
        this._isStageDisplayed = false;
        this._isStealthMode = false;
        this._roomType = RoomType.REGULAR;
    };
}
export const roomStore = new RoomStore();
