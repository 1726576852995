import { jsx as _jsx } from "react/jsx-runtime";
import { ListItem } from '@mui/material';
import { memo, useCallback } from 'react';
import { ItemButton } from '../styled';
import { modalStore } from '@/shared/model/modal';
import Profile from '@/widgets/Profile';
const InnerProfileOption = ({ onClose }) => {
    const handleProfileClick = useCallback(() => {
        onClose();
        modalStore.open({
            content: _jsx(Profile, {}),
        });
    }, [onClose]);
    return (_jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleProfileClick, children: "Profile" }) }));
};
export const ProfileOption = memo(InnerProfileOption);
