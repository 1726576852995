import { makeAutoObservable } from 'mobx';
export class ModalStore {
    _loadingState = false;
    _modalProps = {
        open: false,
        content: null,
        timeout: 500,
    };
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set loadingState(loadingState) {
        if (this._loadingState !== loadingState) {
            this._loadingState = loadingState;
        }
    }
    get loadingState() {
        return this._loadingState;
    }
    open = (props) => {
        this.loadingState = false;
        this._modalProps = {
            open: true,
            ...props,
        };
    };
    close = (callback) => {
        this.loadingState = false;
        this._modalProps.open = false;
        if (callback) {
            setTimeout(() => callback(), this._modalProps.timeout ?? 500);
        }
    };
    get props() {
        return this._modalProps;
    }
}
export const modalStore = new ModalStore();
/**
 * Modals that need to get data from MeetingProvider
 * */
export const roomModalStore = new ModalStore();
