import { InputAdornment } from '@mui/material';
import styled, { css } from 'styled-components';
import InputField from '../InputField';
export const InputFieldStyled = styled(InputField) `
  border-radius: 6px;
  padding: 0;
  position: relative;

  ${({ theme }) => css `
    & .MuiInputLabel-shrink {
      padding: 1px 3px;
      background-color: ${theme.palette.backgroundPrimary.main};
      margin-bottom: 3px;
    }
  `}

  ${({ placeholder }) => placeholder
    ? css `
          & .MuiSelect-select .notranslate::after {
            content: '${placeholder}';
            opacity: 0.42;
          }
        `
    : null}

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -24px;
    ${({ theme }) => css `
      ${theme.breakpoints.down(750)} {
        position: relative;
        bottom: 0;
        margin-bottom: -24px;
      }
    `}
    left: 0;
    margin: 0;
    ${({ theme, error }) => css `
      font-size: ${theme.util.pxToRem(14)};
      color: ${theme.palette[error ? 'error' : 'inactive'].main};
    `}
  }

  & .MuiInputBase-root {
    ${({ $padding }) => $padding
    ? css `
            padding: ${$padding};
          `
    : css `
            padding: 0 14px;
          `}
    ${({ multiline }) => multiline
    ? css `
            padding-right: 0;
          `
    : null}

    ${({ theme, error, color = 'primary' }) => css `
      outline: 1px solid ${theme.palette[error ? 'error' : color].main};
      font-size: ${theme.util.pxToRem(15)};
    `}

    & .MuiInputBase-input {
      padding: 15px;
      border-radius: 0;
      padding-left: 8px;
    }

    & .MuiSelect-icon {
      margin-top: 8px;
      margin-right: 5px;
    }
  }
`;
export const FieldAdornment = styled(InputAdornment) `
  ${({ theme }) => css `
    border-right: 1px solid ${theme.palette.inactive.main};
    height: 100%;

    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      color: ${theme.palette.inactive.main};
      margin-right: 10px;
    }
  `}
`;
