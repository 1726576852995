export var Subdomain;
(function (Subdomain) {
    Subdomain["RL"] = "";
    Subdomain["JA"] = "ja.";
})(Subdomain || (Subdomain = {}));
export const SUBDOMAIN_PREFIXES = {
    rl: {
        path: Subdomain.RL,
    },
    ja: {
        path: Subdomain.JA,
    },
};
