import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppBar, Toolbar, LeftContainer, LogoButton } from './styled';
import { UserActions } from './UserActions';
import { LogoIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
export const LobbyHeader = observer(() => {
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    return (_jsx(AppBar, { bordered: isLobby || isDashboard, children: _jsxs(Toolbar, { children: [_jsx(LeftContainer, { children: _jsx(LogoButton, { color: 'primary', children: _jsx(LogoIcon, { color: 'primary' }) }) }), _jsx(UserActions, {})] }) }));
});
