import styled from 'styled-components';
import { JewishAgencyIcon } from '@/shared/assets/icons';
export const LogoSection = styled.div `
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const JaHeaderJaIcon = styled(JewishAgencyIcon) `
  ${({ theme }) => theme.breakpoints.down(400)} {
    width: 100px;
  }
`;
export const LogoPlus = styled.span `
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  color: #1a141f;
`;
