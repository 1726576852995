export const API = {
    base: process.env.NODE_ENV === 'production'
        ? 'https://api.realush.com' // 'http://localhost:63011',
        : 'https://api.realush.com',
    baseImages: process.env.NODE_ENV === 'production'
        ? 'https://backoffice.realush.com/uploads/images'
        : 'https://backoffice.realush.com/uploads/images',
    refreshTokenPair: '/api/auth/token/refresh',
    verifyAccessToken: '/api/auth/token/verify',
    qr: '/api/qr',
    login: '/api/auth/login',
    signUp: '/api/auth/register',
    reminder: '/api/auth/reminder',
    resetPassword: '/api/login/password/reset',
    validateCode: '/api/login/otp/validate',
    forgotPassword: '/api/login/password/forgot',
    changePassword: '/api/login/password/change',
    becomeHost: '/api/performer/become',
    loginPerformers: '/api/auth/login/performer',
    changePerformerPassword: '/api/login/password/performer/change',
    performerForgotPassword: '/api/login/password/performer/forgot',
    performerValidateCode: '/api/login/otp/performer/validate',
    performerResetPassword: '/api/login/password/performer/reset',
    events: '/api/Event/GetAllEvents',
    upcomingEvents: '/api/Event/GetUpcomingEvents',
    event: '/api/Event',
    eventDetails: '/api/event/GetEventDetails',
    homepageEvents: '/api/Event/homepage',
    rooms: '/api/Room/all',
    roomTables: '/api/Room/GetRoomTables',
    roomDetails: '/api/Room/GetRoomsDetails',
    changeSeat: '/api/Room/UpdateChooseSeat',
    userTable: '/api/User/GetUserTable',
    userDetails: '/api/User/GetUserDetails',
    updateUserDetails: '/api/User/UpdateUserDetails',
    deleteUser: '/api/User',
    userPicture: '/api/User/picture',
    blockUser: 'api/User/block',
    performerDetails: '/api/performer',
    performerCategories: '/api/performer/categories',
    performerActiveCategories: '/api/performer/active-categories',
    countries: 'https://restcountries.com/v3.1/all',
    kickUser: '/api/table/kick',
    assignAsAdmin: '/api/table/admin/assign',
    feedback: '/api/business/feedback',
    friendsChatHistory: '/api/global/chat',
    friendSendChat: '/api/global/chat/send',
    friends: '/api/friend/all',
    sendFriendRequest: '/api/friend/add',
    sendAddFromRoomFriendRequest: '/api/friend/addFromRoom',
    approveFriendRequest: '/api/friend/accept',
    declineFriendRequest: '/api/friend/decline',
    unfriend: '/api/friend',
    unsubscribe: '/api/promo/unsubscribe',
    report: '/api/report',
    setLooking: '/api/procedures/call',
    metrics: '/api/business/metrics',
    rsvp: '/api/rsvp',
    explicitContent: '/api/business/explicitContent',
    analyzeExplicitContent: '/api/business/analyseExplicitContent',
    /** ********************* dev ********************** */
    saveReportLog: 'api/dev/log',
    saveLogMessage: 'api/dev/log-message',
};
