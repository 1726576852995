import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress, IconButton, ListItem } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { observer } from 'mobx-react-lite';
import Popover from '@mui/material/Popover';
import { ActionButton, BottomContainer, ChatContainer, ChatContentContainer, ChatName, Heading, HeadingContentContainer, LoaderWrapper, MessageInput, ProfilePicture, } from './styled';
import ReportForm, { ReportType } from '@/features/ReportForm';
import { ArrowLeftRoundedIcon, MoreIcon, EmojiIcon, SendMessageIcon, ReportIcon, } from '@/shared/assets/icons';
import { usePopoverAnchor, useScrollToInput } from '@/shared/lib/hooks';
import { modalStore } from '@/shared/model/modal';
import ListEnhanced from '@/shared/ui/ListEnhanced';
export const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};
const previewConfig = {
    showPreview: false,
};
const Chat = observer(({ friendId, connected, picture, title, children, onSend, onClose, addPictureBorder, isUserDeleted, removeMoreActions, }) => {
    const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
    const [message, setMessage] = useState('');
    const inputRef = useScrollToInput();
    const { open, anchorEl, handleClose, handleOpen } = usePopoverAnchor();
    const handleReport = useCallback(() => {
        if (!friendId)
            return;
        modalStore.open({
            content: _jsx(ReportForm, { reportedId: friendId, roomId: 0, reportType: ReportType.CHAT }),
        });
        handleClose();
    }, [friendId, handleClose]);
    const handleSend = useCallback(async () => {
        if (!message.trim())
            return;
        onSend(message);
        setMessage('');
    }, [message, onSend]);
    const handleChangeMessage = useCallback((event) => {
        setMessage(event.target.value);
    }, []);
    const handleKeyDown = useCallback((event) => {
        if ((event.key === 'Enter' || event.key === 'Shift') && event.ctrlKey) {
            setMessage((prevMessage) => `${prevMessage}\n`);
            return;
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        }
    }, [handleSend]);
    const handleOpenEmojiPicker = useCallback(() => {
        setOpenEmojiPicker((prevState) => !prevState);
    }, []);
    const handleEmojiClick = useCallback((emojiData, _) => {
        setMessage((prevState) => prevState + emojiData.emoji);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(ChatContainer, { children: [_jsxs(Heading, { component: 'header', elevation: 1, children: [_jsx(HeadingContentContainer, { gap: '13px', children: _jsx(IconButton, { color: 'darkGray', onClick: onClose, size: 'small', children: _jsx(ArrowLeftRoundedIcon, { height: 24, width: 24 }) }) }), _jsxs(HeadingContentContainer, { gap: '8px', children: [_jsx(ProfilePicture, { src: picture, alt: title, "$withPictureBorder": addPictureBorder }), _jsx(ChatName, { children: title })] }), _jsx(IconButton, { size: 'small', onClick: removeMoreActions ? undefined : handleOpen, "aria-label": 'More', sx: removeMoreActions ? { visibility: 'hidden' } : undefined, children: _jsx(MoreIcon, {}) })] }), _jsx(ChatContentContainer, { children: connected ? (children) : (_jsx(LoaderWrapper, { children: _jsx(CircularProgress, { color: 'secondary' }) })) }), openEmojiPicker ? (_jsx(EmojiPicker, { lazyLoadEmojis: true, skinTonesDisabled: true, previewConfig: previewConfig, height: 500, width: '100%', onEmojiClick: handleEmojiClick })) : null, isUserDeleted ? null : (_jsxs(BottomContainer, { children: [_jsx(IconButton, { size: 'small', onClick: handleOpenEmojiPicker, children: _jsx(EmojiIcon, {}) }), _jsx(MessageInput, { ref: inputRef, placeholder: 'Start typing...', value: message, onChange: handleChangeMessage, autoFocus: true, multiline: true, maxRows: 3, fullWidth: true, onKeyDown: handleKeyDown }), _jsx(IconButton, { size: 'small', color: 'purple', onClick: handleSend, children: _jsx(SendMessageIcon, {}) })] }))] }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, children: _jsx(ListEnhanced, { children: _jsx(ListItem, { disableGutters: true, children: _jsxs(ActionButton, { onClick: handleReport, children: [_jsx(ReportIcon, {}), "Report"] }) }) }) })] }));
});
export default memo(Chat);
