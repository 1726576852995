import { makeAutoObservable } from 'mobx';
import { SoundType } from '../lib/types';
class SoundsStore {
    _backgroundSoundRef = null;
    _notificationSoundRef = null;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get backgroundSoundRef() {
        return this._backgroundSoundRef;
    }
    set backgroundSoundRef(backgroundSoundRef) {
        this._backgroundSoundRef = backgroundSoundRef;
    }
    get notificationSoundRef() {
        return this._notificationSoundRef;
    }
    set notificationSoundRef(notificationSoundRef) {
        this._notificationSoundRef = notificationSoundRef;
    }
    play = (type) => {
        if (type === SoundType.NOTIFICATION) {
            if (!this._notificationSoundRef?.current) {
                // eslint-disable-next-line no-console
                console.log('Notification sound element did not provided. Can`t play notification sound.');
                return;
            }
            this._notificationSoundRef.current.play();
        }
    };
    initSounds = () => {
        if (this._backgroundSoundRef?.current) {
            this._backgroundSoundRef.current.play();
        }
        if (this._notificationSoundRef?.current) {
            this._notificationSoundRef.current.muted = true;
            this._notificationSoundRef.current.play();
            this._notificationSoundRef.current.muted = false;
        }
    };
}
export const soundsStore = new SoundsStore();
