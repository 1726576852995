import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ContentActions, ContentHeading, ModalContent } from './styled';
import { modalStore, roomModalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import ButtonCustom from '@/shared/ui/ButtonCustom';
const InnerWrongApp = () => {
    const handleClose = () => {
        modalStore.close();
        roomModalStore.close();
    };
    return (_jsxs(ModalContent, { width: 300, children: [_jsx(ContentHeading, { children: _jsx(TextElement, { fontSize: 24, fontWeight: 600, component: 'h6', textAlign: 'center', children: "Your account is not JA. Try switch to other app version." }) }), _jsx(ContentActions, { children: _jsx(ButtonCustom, { variant: 'contained', onClick: handleClose, height: 48, width: 162, children: "Got it" }) })] }));
};
export const WrongApp = memo(InnerWrongApp);
