export var InteractionsMessagesType;
(function (InteractionsMessagesType) {
    InteractionsMessagesType[InteractionsMessagesType["AdminAssignment"] = 0] = "AdminAssignment";
    InteractionsMessagesType[InteractionsMessagesType["UserKicked"] = 1] = "UserKicked";
    InteractionsMessagesType[InteractionsMessagesType["RequestToJoin"] = 2] = "RequestToJoin";
    InteractionsMessagesType[InteractionsMessagesType["AcceptedRequest"] = 3] = "AcceptedRequest";
    InteractionsMessagesType[InteractionsMessagesType["PassedRequest"] = 4] = "PassedRequest";
    InteractionsMessagesType[InteractionsMessagesType["GroupMessage"] = 5] = "GroupMessage";
    InteractionsMessagesType[InteractionsMessagesType["InviteToTable"] = 6] = "InviteToTable";
    InteractionsMessagesType[InteractionsMessagesType["AcceptedInvite"] = 7] = "AcceptedInvite";
    InteractionsMessagesType[InteractionsMessagesType["PassedInvite"] = 8] = "PassedInvite";
    InteractionsMessagesType[InteractionsMessagesType["SendNote"] = 9] = "SendNote";
    InteractionsMessagesType[InteractionsMessagesType["RepliedNote"] = 10] = "RepliedNote";
    InteractionsMessagesType[InteractionsMessagesType["PassedNote"] = 11] = "PassedNote";
    InteractionsMessagesType[InteractionsMessagesType["PassedReply"] = 12] = "PassedReply";
    InteractionsMessagesType[InteractionsMessagesType["StartedEvent"] = 13] = "StartedEvent";
    InteractionsMessagesType[InteractionsMessagesType["EndedEvent"] = 14] = "EndedEvent";
})(InteractionsMessagesType || (InteractionsMessagesType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["CLAP"] = "CLAP";
})(NotificationType || (NotificationType = {}));
