import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useLayoutEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode, isAxiosError } from 'axios';
import { Content, CloseButton, Title, SignInFormWrapper, SignUpHelperText, ErrorMessage, ContentHeader, } from './styled';
import { defaultValues, registerScheme } from './model';
import MobileRestrictionForm from '@/entities/notifications/MobileRestriction';
import AuthService from '@/shared/api/services/AuthService';
import { CloseIcon, SmsIcon, LockIcon } from '@/shared/assets/icons';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore, roomModalStore } from '@/shared/model/modal';
import StorageService from '@/shared/lib/utils/storage';
import InputFormField from '@/shared/ui/InputFormField';
import RouteLink from '@/shared/ui/RouteLink';
import SubmitButton from '@/shared/ui/SubmitButton';
import { ROLES } from '@/shared/constants/roles';
import { BlockedAlert } from '@/entities/notifications/BlockedAlert';
import { getIsMobileDevice } from '@/shared/utils/getIsMobileDevice';
import { WrongApp } from '@/entities/notifications/WrongApp';
import { Logo } from '@/entities/logo';
const SignInForm = ({ title, to = ROUTES.rooms.path, type = ROLES.user, disableHelperText, helperText = {
    label: 'Don’t have an account?',
    to: ROUTES.signUp.path,
    linkLabel: 'Sign up',
}, skipMobile, }) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const isMobile = getIsMobileDevice();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors, isValid }, setFocus, } = useForm({
        defaultValues,
        resolver: zodResolver(registerScheme),
    });
    const handleClose = useCallback(() => {
        modalStore.close();
        roomModalStore.close();
    }, []);
    useLayoutEffect(() => {
        setFocus('email');
    }, [setFocus]);
    const submit = useCallback(async (data) => {
        if (!isValid)
            return;
        setLoading(true);
        const response = await AuthService.loginByRole({ role: type, ...data });
        if (!response || isAxiosError(response)) {
            if (response?.response?.status === HttpStatusCode.Forbidden) {
                const { status } = response.response.data ?? {};
                if (status === 'WRONG_APP') {
                    modalStore.open({ content: _jsx(WrongApp, {}) });
                    return;
                }
                modalStore.open({ content: _jsx(BlockedAlert, {}) });
                return;
            }
            setErrorMessage('Email or password are incorrect');
            setLoading(false);
            return;
        }
        setLoading(false);
        const { accessToken } = response.data;
        StorageService.set(STORAGE_KEYS.accessToken, accessToken);
        StorageService.remove(STORAGE_KEYS.orientation);
        navigate(to);
        handleClose();
    }, [isValid, type, navigate, to, handleClose]);
    return isMobile && !skipMobile ? (_jsx(MobileRestrictionForm, {})) : (_jsxs(Content, { children: [_jsxs(ContentHeader, { children: [_jsx(CloseButton, { onClick: handleClose, children: _jsx(CloseIcon, {}) }), _jsx(Logo, {}), _jsx(Title, { children: title })] }), _jsxs(SignInFormWrapper, { onSubmit: handleSubmit(submit), children: [_jsx(Controller, { name: 'email', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, fullWidth: true, Icon: SmsIcon, onChange: (event) => {
                                onChange(event);
                                setErrorMessage('');
                            }, error: !!errors.email || !!errorMessage, helperText: errors.email?.message, type: 'email', placeholder: 'Email' })) }), _jsx(Controller, { name: 'password', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(InputFormField, { ...field, inputRef: ref, type: 'password', Icon: LockIcon, fullWidth: true, onChange: (event) => {
                                onChange(event);
                                setErrorMessage('');
                            }, error: !!errors.password || !!errorMessage, placeholder: 'Password', helperText: errors.password?.message })) }), _jsx(RouteLink, { to: type === ROLES.user ? ROUTES.forgotPassword.path : ROUTES.forgotPassword.performer.path, color: 'primary', onClick: handleClose, underline: 'hover', children: "Forgot Password?" }), _jsx(SubmitButton, { fontSize: 16, fontWeight: 600, loading: loading, fullWidth: true, height: 48, children: "Sign in" }), errorMessage ? _jsx(ErrorMessage, { children: errorMessage }) : null] }), disableHelperText ? null : (_jsxs(SignUpHelperText, { children: [helperText.label, _jsx(RouteLink, { to: helperText.to, color: 'primary', onClick: handleClose, underline: 'hover', children: helperText.linkLabel })] }))] }));
};
export default memo(SignInForm);
