import { makeAutoObservable } from 'mobx';
import { OPPOSITE_GENDERS } from '../constants/genders';
import { MAX_AGE, MIN_AGE } from '../constants/ages';
import { STORAGE_KEYS } from '@/shared/constants/common';
import StorageService from '@/shared/lib/utils/storage';
class SpeedDatingStore {
    _selectedGender = '';
    _selectedAge = [];
    _isParticipatingInRound = false;
    _matchedUserInfo = null;
    _isMatched = false;
    _isClosed = true;
    _isInDate = false;
    _withoutDateFromRoundId = -1;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get selectedGender() {
        return this._selectedGender;
    }
    set selectedGender(selectedGender) {
        this._selectedGender = selectedGender;
    }
    get selectedAge() {
        return this._selectedAge;
    }
    set selectedAge(selectedAge) {
        this._selectedAge = selectedAge;
    }
    get isMatched() {
        return this._isMatched;
    }
    set isMatched(isMatched) {
        this._isMatched = isMatched;
    }
    get isClosed() {
        return this._isClosed;
    }
    set isClosed(isClosed) {
        this._isClosed = isClosed;
    }
    get isParticipatingInRound() {
        return this._isParticipatingInRound;
    }
    set isParticipatingInRound(isParticipatingInRound) {
        this._isParticipatingInRound = isParticipatingInRound;
    }
    get isInDate() {
        return this._isInDate;
    }
    set isInDate(isInDate) {
        this._isInDate = isInDate;
    }
    get matchedUserInfo() {
        return this._matchedUserInfo;
    }
    set matchedUserInfo(matchedUserInfo) {
        this._matchedUserInfo = matchedUserInfo;
    }
    get withoutDateFromRoundId() {
        return this._withoutDateFromRoundId;
    }
    set withoutDateFromRoundId(withoutDateFromRoundId) {
        this._withoutDateFromRoundId = withoutDateFromRoundId;
    }
    initFilters = (user) => {
        const filters = StorageService.get(STORAGE_KEYS.speedDatingFilters);
        if (filters) {
            this.selectedGender = filters.gender;
            this.selectedAge = filters.age;
            return;
        }
        this.selectedGender = OPPOSITE_GENDERS[user.user.gender];
        this.selectedAge = [Math.max(user.age - 5, MIN_AGE), Math.min(user.age + 5, MAX_AGE)];
    };
    clear = () => {
        this._isMatched = false;
        this._isInDate = false;
        this._withoutDateFromRoundId = -1;
        this._matchedUserInfo = null;
        this._isParticipatingInRound = false;
    };
}
export const speedDatingStore = new SpeedDatingStore();
