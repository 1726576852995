export var ReceiveInteractionsMessages;
(function (ReceiveInteractionsMessages) {
    ReceiveInteractionsMessages["message"] = "ReceiveMessage";
    ReceiveInteractionsMessages["tables"] = "ReceiveTables";
    ReceiveInteractionsMessages["roomAccessRejected"] = "RejectedRoomAccess";
    ReceiveInteractionsMessages["inviteToTable"] = "InviteToTableReceive";
    ReceiveInteractionsMessages["requestToJoin"] = "RequestToJoinReceive";
    ReceiveInteractionsMessages["approveInviteToTable"] = "ApproveInviteToTableReceive";
    ReceiveInteractionsMessages["declineInviteToTable"] = "DeclineInviteToTableReceive";
    ReceiveInteractionsMessages["approveRequestToJoin"] = "ApproveRequestToJoinReceive";
    ReceiveInteractionsMessages["declineRequestToJoin"] = "DeclineRequestToJoinReceive";
    ReceiveInteractionsMessages["checkNotificationStatus"] = "CheckNotificationStatusReceive";
    ReceiveInteractionsMessages["leaveTable"] = "LeaveFromTableReceive";
    ReceiveInteractionsMessages["initialSeat"] = "InitialSeatReceive";
    ReceiveInteractionsMessages["moveToSeat"] = "MoveToSeatReceive";
    ReceiveInteractionsMessages["lookedBy"] = "LookedBy";
    ReceiveInteractionsMessages["passNote"] = "PassNoteReceive";
    ReceiveInteractionsMessages["stageList"] = "StageList";
    ReceiveInteractionsMessages["stageListPerformer"] = "StageListPerformer";
    ReceiveInteractionsMessages["rejectToJoinStage"] = "RejectRequestToJoinStageReceive";
    ReceiveInteractionsMessages["speedDatingRoundStarted"] = "SpeedDatingRoundStarted";
    ReceiveInteractionsMessages["speedDatingRoundEnded"] = "SpeedDatingRoundEnded";
    ReceiveInteractionsMessages["speedDatingRoundParticipation"] = "SpeedDatingRoundParticipation";
    ReceiveInteractionsMessages["speedDatingMatch"] = "SpeedDatingMatch";
    ReceiveInteractionsMessages["speedDatingUndoMatch"] = "SpeedDatingUndoMatch";
    ReceiveInteractionsMessages["sendRoomQuestion"] = "SendRoomQuestion";
    ReceiveInteractionsMessages["receiveRoomQuestionAnswers"] = "ReceiveRoomQuestionAnswers";
    ReceiveInteractionsMessages["fixStreamId"] = "FixStreamId";
    ReceiveInteractionsMessages["inviteToTableInviterReceive"] = "InviteToTableInviterReceive";
    ReceiveInteractionsMessages["attemptedToJoinFullTable"] = "AttemptedToJoinFullTable";
    ReceiveInteractionsMessages["startRandomPlacement"] = "startRandomPlacement";
    ReceiveInteractionsMessages["notificationsReceive"] = "NotificationsReceive";
})(ReceiveInteractionsMessages || (ReceiveInteractionsMessages = {}));
